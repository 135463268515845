import React from "react";
import ReactHtmlParser from "react-html-parser";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { transformLink } from "../../../helpers";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

export const CoreHeadingBlock = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      {props.attributes.anchor !== "" && (
        <div id={`${props.attributes.anchor}`}></div>
      )}
      <Typography
        component={
          props.attributes.level !== "" ? `h${props.attributes.level}` : "h2"
        }
        variant={
          props.attributes.level !== "" ? `h${props.attributes.level}` : "h2"
        }
        color="initial"
        align={
          props.attributes.textAlign !== ""
            ? props.attributes.textAlign
            : "left"
        }
        className={` ${props.attributes.className} ${classes.header} ${classes.headerTheme}`}
        data-text-color={
          props.attributes.textColor !== ""
            ? props.attributes.textColor
            : "initial"
        }
        data-bg-color={
          props.attributes.backgroundColor !== ""
            ? props.attributes.backgroundColor
            : "initial"
        }
      >
        {ReactHtmlParser(props.attributes.content, {
          transform: transformLink,
        })}
      </Typography>
    </React.Fragment>
  );
};

CoreHeadingBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const CoreHeadingBlockFragment = graphql`
  fragment CoreHeadingBlock on WpBlock {
    ... on WpCoreHeadingBlock {
      name
      attributes {
        __typename
        ... on WpCoreHeadingBlockAttributes {
          level
          anchor
          textColor
          backgroundColor
          className
          align
          content
          textAlign
        }
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CoreHeadingBlockPreview = `
... on CoreHeadingBlock {
  name
  attributes {
    __typename
    ... on CoreHeadingBlockAttributes {
      level
      anchor
      textColor
      backgroundColor
      className
      align
      content
      textAlign
    }
  }
}
`;
